export const API_URL = "https://api.secondstage.app"
export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#b32135', '#b32135'],
        home_question: ['#2f3871', '#2f3871'],
        applausometro: ['#E5634A', '#E5448A'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#EA4D95', '#E76E51'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#b32135',
    secondary: '#263238',
    accent: '#b32135',
    text: '#eceff1',
    background:"#2e3970",
    poll:{
        default:"#414272",
        answered:"#b32135",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}